import Menu from 'mmenu-js';

export default () => {
  const template = document.querySelector('#mmenu-template')?.innerHTML;

  new Menu(
    '#wlymmenu',
    {
      wrappers: ['bootstrap'],
      extensions: ['fx-listitems-fade', 'border-offset', 'pagedim-black', 'theme-black'],
      onClick: {
        close: true,
        setSelected: true,
      },
      setSelected: {
        current: 'detect',
      },
      slidingSubmenus: true,
      navbars: [
        {
          position: 'bottom',
          content: [template],
        },
      ],
    },
    {
      setSelected: {
        //
      },
    }
  );
};
